<template>
  <div id="container">
    <section id="contact">
      <div class="wrap">
        <div class="cts_box">
          <h3 class="sub_ttl"><span>お客様のお問い合わせを受け付けました</span></h3>
          <p class="lead_txt">
            以下の内容でお問い合わせを受け付けました。お問い合わせの内容を確認の上、ご入力いただいたメールアドレス宛にご連絡差し上げます。<br>
            お問い合わせいただきありがとうございました。
          </p>
            <p class="lead_txt">
            なお、当塾事務局は平日10:00-17:00の営業となっております。<br>
            長期連休、年末年始等は営業開始後に対応いたします。<br>
            ご返信までしばらくお時間をいただく場合がございますので、予めご了承ください。
          </p>
          <div class="df_table">
            <dl class="flex">
              <dt>氏名</dt>
              <dd>{{ contact.last_name }} {{ contact.first_name }}</dd>
            </dl>
            <dl class="flex">
              <dt>塾生番号</dt>
              <dd>{{ contact.number }}</dd>
            </dl>
            <dl class="flex">
              <dt>連絡先メールアドレス</dt>
              <dd>{{ contact.email }}</dd>
            </dl>
            <dl class="flex">
              <dt>連絡先電話番号</dt>
              <dd>{{ contact.phone_number }}</dd>
            </dl>
            <dl class="flex">
              <dt>お問い合わせ内容</dt>
              <dd style="white-space: pre-wrap; word-wrap:break-word">
                {{ contact.description }}
              </dd>
            </dl>
          </div>

        </div>
        <div class="cts_box">
          <div class="button_wrap">
            <router-link to="/" class="submit basic arrow">トップページにもどる</router-link>
          </div>
        </div>
      </div>
    </section>
    <div id="b_listBox">
      <ul id="b_list">
        <li><router-link href="/">トップページ</router-link></li>
        <li><router-link to="/contacts/">お問い合わせ</router-link></li>
        <li>お客様のお問い合わせを受け付けました></li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      contact: this.$store.state.contact ? this.$store.state.contact : {}
    }
  },
  mounted() {
    if (!this.$store.state.contact) {
      this.$router.push({name: 'Home'})
    }
  }
}
</script>
